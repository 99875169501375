import { NightsStay, WbSunny } from '@mui/icons-material';
import { Link } from '@mui/material';
import {
  StyledAppBar,
  StyledIconButton,
  StyledRightBox,
  StyledToolbar,
  StyledTypography,
} from './Navbar.styles';

import { ComponentProps } from 'react';

interface NavbarProps extends ComponentProps<typeof StyledAppBar> {
  isDarkMode: boolean;
  handleThemeChange?: () => void;
}

const Navbar = ({
  isDarkMode,
  handleThemeChange,
  position,
  sx,
}: NavbarProps) => {
  return (
    <StyledAppBar position={position} sx={sx}>
      <StyledToolbar>
        {/* Styled QuickreplyIcon */}

        {/* Styled Typography */}
        <Link href="/" color="inherit" underline="none">
          <StyledTypography variant="caption" noWrap>
            CREATE WITHOUT LIMITS
          </StyledTypography>
        </Link>
        <StyledRightBox>
          <StyledTypography variant="caption" noWrap>
            ABN 82 639 338 809
          </StyledTypography>
        </StyledRightBox>

        {/* Day/Night Icon based on theme, click to toggle */}
        {typeof handleThemeChange === 'function' && (
          <StyledIconButton
            onClick={handleThemeChange}
            color="inherit"
            aria-label={
              isDarkMode ? 'Switch to light mode' : 'Switch to dark mode'
            }
          >
            {isDarkMode ? <NightsStay /> : <WbSunny />}
          </StyledIconButton>
        )}
      </StyledToolbar>
    </StyledAppBar>
  );
};

export default Navbar;
