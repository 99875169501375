import logoDark from '../../assets/images/logo-dark_bg.png';
import { Container, Logo } from './Home.styles';

interface HomeProps {
  isDarkMode: boolean;
}

const Home = ({ isDarkMode }: HomeProps) => {
  return (
    <Container>
      <Logo src={isDarkMode ? logoDark : logoDark} alt="Directive 101 Logo" />
    </Container>
  );
};

export default Home;
