import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import styled from 'styled-components';

// Styled-components for Material UI components
export const StyledAppBar = styled(AppBar)`
  position: static;
`;

export const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    justify-content: center;
    flex-direction: column; // Stack items vertically on small screens
    align-items: flex-start; // Align items to start for better readability
  }
`;

export const StyledTypography = styled(Typography)`
  font-weight: 700;
  letter-spacing: 0.3rem;
  color: inherit;
  text-decoration: none;
  flex-grow: 1;
`;

export const StyledIconButton = styled(IconButton)`
  color: inherit;
`;

export const StyledRightBox = styled(Box)``;
