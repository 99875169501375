import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home/Home';
import { darkTheme } from './themes/dark';
import { lightTheme } from './themes/light';
import { muiDarkTheme, muiLightTheme } from './themes/mui';

const App = () => {
  const isDarkMode = true;

  const muiTheme = isDarkMode ? muiDarkTheme : muiLightTheme;

  return (
    <StyledThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />
        <Router>
          <div
            id="app_cotainer"
            style={{ textAlign: 'center', padding: '0 15px' }}
          >
            <Routes>
              <Route path="/" element={<Home isDarkMode={isDarkMode} />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
          <Navbar
            position="fixed"
            sx={{ top: 'auto', bottom: 0 }}
            isDarkMode={isDarkMode}
          />
        </Router>
      </MuiThemeProvider>
    </StyledThemeProvider>
  );
};

export default App;
